import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { KeviliSharedModule } from 'app/shared';

import {
  accountState,
  ActivateComponent,
  PasswordComponent,
  PasswordResetFinishComponent,
  PasswordResetInitComponent,
  PasswordStrengthBarComponent,
  RegisterComponent,
  LoginComponent,
  SettingsComponent
} from './';

@NgModule({
  imports: [KeviliSharedModule, RouterModule.forChild(accountState)],
  declarations: [
    LoginComponent,
    ActivateComponent,
    RegisterComponent,
    PasswordComponent,
    PasswordStrengthBarComponent,
    PasswordResetInitComponent,
    PasswordResetFinishComponent,
    SettingsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class KeviliAccountModule {}
