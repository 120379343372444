import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { GeneralFile } from 'app/core/file/file.model';
import { SharedService } from 'app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  public resourceUrl = SERVER_API_URL + 'api/';

  constructor(public http: HttpClient, private sharedService: SharedService) {}

  getFiles() {
    return this.http.get<GeneralFile[]>(this.resourceUrl + 'documents/all', { observe: 'response' });
  }

  getFile(name: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.resourceUrl + 'download', { params: { filename: name }, responseType: 'blob', observe: 'response' });
  }

  uploadFileForSigning(contract): Promise<any> {
    if (contract.contractBDOC !== '') {
      return this.postFile(this.getContractBDOC(contract.contractBDOC), contract.contractNo + '.bdoc');
    } else {
      return this.sharedService
        .getPDF(contract.contractPDF)
        .toPromise()
        .then(res => {
          return this.postFile(res, contract.contractNo + '.pdf');
        });
    }
  }

  postFile(content, name): Promise<any> {
    return this.post(new File([content], name), 'file', 'upload')
      .toPromise()
      .then(res => {
        return res;
      });
  }

  fetchHash(certInHex: string): Promise<any> {
    return this.getPromise(certInHex, 'certInHex', 'generateHash');
  }

  createContainer(signatureInHex) {
    return this.getPromise(signatureInHex, 'signatureInHex', 'createContainer');
  }

  uploadContainer(signAsVendor, etag) {
    return this.postContainer(signAsVendor, etag)
      .toPromise()
      .then(res => {
        if (res.result !== 'ok') {
          return Promise.reject(Error(res.result));
        } else {
          return Promise.resolve(res);
        }
      });
  }

  postContainer(signAsVendor, etag): Observable<any> {
    const formData = new FormData();
    formData.append('signAsVendor', signAsVendor);
    formData.append('etag', etag);
    return this.http.post(this.resourceUrl + 'uploadContainer', formData);
  }

  post(data: any, name: string, url: string): Observable<any> {
    const formData = new FormData();
    formData.append(name, data);
    return this.http.post(this.resourceUrl + url, formData);
  }

  getPromise(data: any, name: string, url: string): Promise<any> {
    return this.post(data, name, url)
      .toPromise()
      .then(res => {
        if (res.result !== 'ok') {
          return Promise.reject(Error(res.result));
        } else {
          return Promise.resolve(res);
        }
      });
  }

  getContractBDOC(content) {
    return new Blob([this.sharedService.s2ab(atob(content))], {
      type: 'application/vnd.etsi.asic-e+zip'
    });
  }

  signSmartIdInit(): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'sign/smartid/init', null, { observe: 'response' });
  }

  signSmartIdFinish(): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'sign/smartid/finish', null, { observe: 'response' });
  }

  signMidInit(phoneNo: string): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'sign/mid/init', phoneNo, { observe: 'response' });
  }

  signMidFinish(): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'sign/mid/finish', null, { observe: 'response' });
  }
}
