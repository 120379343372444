import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from 'ng-jhipster';
import { Router } from '@angular/router';

import { Account, AccountService, LoginModalService } from 'app/core';
import { ROLES_MEMBER_AGENT_NON_MEMBER, ROLES_TERMINAL_OPERATOR_TECHNICIAN } from 'app/app.constants';

@Component({
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.scss']
})
export class HomeComponent implements OnInit {
  account: Account;
  modalRef: NgbModalRef;

  constructor(
    private accountService: AccountService,
    private loginModalService: LoginModalService,
    private eventManager: JhiEventManager,
    private router: Router
  ) {}

  ngOnInit() {
    this.accountService.identity().then((account: Account) => {
      this.account = account;
    });
    this.registerAuthenticationSuccess();
    if (this.accountService.hasAnyAuthority(ROLES_TERMINAL_OPERATOR_TECHNICIAN)) {
      this.router.navigate(['/terminal']);
    } else if (this.accountService.hasAnyAuthority(ROLES_MEMBER_AGENT_NON_MEMBER)) {
      this.router.navigate(['/selfservice']);
    } else {
      this.router.navigate(['/admin']);
    }
  }

  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', message => {
      this.accountService.identity().then(account => {
        this.account = account;
      });
    });
  }
}
