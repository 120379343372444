import { Injectable } from '@angular/core';

import { AccountService } from 'app/core/auth/account.service';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class LoginService {
  public resourceUrl = SERVER_API_URL + 'api/authenticate/';

  constructor(private accountService: AccountService, private authServerProvider: AuthServerProvider, private http: HttpClient) {}

  login(credentials, callback?) {
    const cb = callback || function() {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        data => {
          this.accountService.identity(true).then(() => {
            resolve(data);
          });
          return cb();
        },
        err => {
          this.logout();
          reject(err);
          return cb(err);
        }
      );
    });
  }

  loginIdCard(callback?) {
    const cb = callback || function() {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.loginWithIdCard().subscribe(
        data => {
          this.accountService.identity(true).then(() => {
            resolve(data);
          });
          return cb();
        },
        err => {
          this.logout();
          reject(err);
          return cb(err);
        }
      );
    });
  }

  loginSmartIdInit(idCode: string): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'smartid/init', idCode, { observe: 'response' });
  }

  loginSmartIdPoll(callback?) {
    const cb = callback || function() {};
    return new Promise((resolve, reject) => {
      this.authServerProvider.loginWithSmartId().subscribe(
        data => {
          this.accountService.identity(true).then(() => {
            resolve(data);
          });
          return cb();
        },
        err => {
          this.logout();
          reject(err);
          return cb(err);
        }
      );
    });
  }

  loginMobileIdInit(idCode: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl + 'mid/init', idCode, { observe: 'response' });
  }

  loginMobileIdPoll(callback?) {
    const cb = callback || function() {};
    return new Promise((resolve, reject) => {
      this.authServerProvider.loginWithMobileId().subscribe(
        data => {
          this.accountService.identity(true).then(() => {
            resolve(data);
          });
          return cb();
        },
        err => {
          this.logout();
          reject(err);
          return cb(err);
        }
      );
    });
  }

  loginWithToken(jwt, rememberMe) {
    return this.authServerProvider.loginWithToken(jwt, rememberMe);
  }

  logout() {
    this.authServerProvider.logout().subscribe(null, null, () => this.accountService.authenticate(null));
  }
}
