/* after changing this file run 'npm run webpack:build' */
/* tslint:disable */
import '../content/scss/vendor.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowLeft,
  faAsterisk,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBook,
  faBriefcase,
  faBroom,
  faCalculator,
  faCalendarAlt,
  faCartPlus,
  faCheck,
  faClock,
  faCloud,
  faCubes,
  faEye,
  faFileAlt,
  faFileContract,
  faFileSignature,
  faFilter,
  faFlag,
  faHdd,
  faHeart,
  faHome,
  faList,
  faMapMarker,
  faPencilAlt,
  faPlus,
  faPrint,
  faRoad,
  faSave,
  faSearch,
  faSearchDollar,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faStore,
  faSync,
  faSyncAlt,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimes,
  faTrashAlt,
  faTruck,
  faUser,
  faUserPlus,
  faUsers,
  faWarehouse,
  faWrench,
  faMinus,
  faFileExport,
  faIdBadge,
  faArrowRight,
  faFileInvoice,
  faFileImport,
  faUndo,
  faChevronDown,
  faChevronUp,
  faCopy
} from '@fortawesome/free-solid-svg-icons';

// Imports all fontawesome core and solid icons

// Adds the SVG icon to the library so you can use it in your page
library.add(faUser);
library.add(faUsers);
library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSync);
library.add(faEye);
library.add(faBan);
library.add(faTimes);
library.add(faArrowLeft);
library.add(faSave);
library.add(faPlus);
library.add(faPencilAlt);
library.add(faBars);
library.add(faHome);
library.add(faThList);
library.add(faUserPlus);
library.add(faRoad);
library.add(faTachometerAlt);
library.add(faHeart);
library.add(faList);
library.add(faBell);
library.add(faTasks);
library.add(faBook);
library.add(faHdd);
library.add(faFlag);
library.add(faWrench);
library.add(faClock);
library.add(faCloud);
library.add(faSignOutAlt);
library.add(faSignInAlt);
library.add(faCalendarAlt);
library.add(faSearch);
library.add(faTrashAlt);
library.add(faAsterisk);
library.add(faCheck);
library.add(faPrint);
library.add(faCalculator);
library.add(faSyncAlt);
library.add(faFileSignature);
library.add(faFileContract);
library.add(faFileInvoice);
library.add(faFileAlt);
library.add(faMapMarker);
library.add(faSearchDollar);
library.add(faWarehouse);
library.add(faArrowAltCircleDown);
library.add(faArrowAltCircleUp);
library.add(faCubes);
library.add(faFilter);
library.add(faBroom);
library.add(faBalanceScale);
library.add(faStore);
library.add(faCartPlus);
library.add(faBriefcase);
library.add(faMinus);
library.add(faFileExport);
library.add(faIdBadge);
library.add(faArrowRight);
library.add(faTruck);
library.add(faFileImport);
library.add(faUndo);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faCopy);
// jhipster-needle-add-element-to-vendor - JHipster will add new menu items here
