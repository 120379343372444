import { Injectable } from '@angular/core';
import { User } from 'app/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from 'app/app.constants';
import { Contact } from 'app/core/user/contact.model';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  public resourceUrl = SERVER_API_URL + 'api/agent/';

  constructor(private http: HttpClient) {}

  fetchRepresentedUsers(): Observable<HttpResponse<User[]>> {
    return this.http.get<Contact[]>(this.resourceUrl + 'users', { observe: 'response' });
  }
}
