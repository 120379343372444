import { NgModule } from '@angular/core';

import { KeviliSharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent } from './';
import { ConfirmDialogComponent } from 'app/shared/common/confirm-dialog.component';
import { ConfirmDialogUtil } from 'app/shared/common/confirm-dialog.utils';
import { DropdownComponent } from 'app/shared/dropdown/dropdown.component';

@NgModule({
  imports: [KeviliSharedLibsModule],
  declarations: [JhiAlertComponent, JhiAlertErrorComponent, ConfirmDialogComponent, DropdownComponent],
  providers: [ConfirmDialogUtil],
  exports: [KeviliSharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent, ConfirmDialogComponent, DropdownComponent]
})
export class KeviliSharedCommonModule {}
