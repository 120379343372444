// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const VERSION = process.env.VERSION;
export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const ROLE_MEMBER = 'ROLE_MEMBER';
export const ROLE_TERMINAL = 'ROLE_TERMINAL';
export const ROLE_NON_MEMBER = 'ROLE_NON_MEMBER';
export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_OPERATOR = 'ROLE_OPERATOR';
export const ROLE_TECHNICIAN = 'ROLE_TECHNICIAN';

export const ROLES_MEMBER_AGENT = [ROLE_MEMBER, ROLE_AGENT];
export const ROLES_MEMBER_AGENT_NON_MEMBER = [ROLE_MEMBER, ROLE_AGENT, ROLE_NON_MEMBER];
export const ROLES_TERMINAL_OPERATOR_TECHNICIAN = [ROLE_TERMINAL, ROLE_OPERATOR, ROLE_TECHNICIAN];
export const ROLES_OPERATOR_TECHNICIAN = [ROLE_OPERATOR, ROLE_TECHNICIAN];
export const ROLES_ANY = [ROLE_TERMINAL, ROLE_OPERATOR, ROLE_TECHNICIAN, ROLE_MEMBER, ROLE_AGENT, ROLE_NON_MEMBER, ROLE_ADMIN];
