import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogUtil {
  constructor(private modalService: NgbModal) {}

  open(
    title: string,
    message: string,
    callbackConfirm?: any,
    callbackCancel?: any,
    parent?: NgbActiveModal,
    noCancel?: boolean,
    yesButton?: string
  ) {
    const dialog = <ConfirmDialogComponent>(
      this.modalService.open(ConfirmDialogComponent, { backdrop: 'static', windowClass: 'confirm-dialog' }).componentInstance
    );
    if (parent) {
      dialog.setParentModal(parent);
    }
    dialog.setTitle(title);
    dialog.setMessage(message);
    if (noCancel) {
      dialog.setNoCancel(noCancel);
    }
    if (yesButton) {
      dialog.setYesButton(yesButton);
    }
    if (callbackCancel) {
      dialog.onCancel(callbackCancel);
    }
    if (callbackConfirm) {
      dialog.onConfirm(callbackConfirm);
    }
  }
}
