import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HasAnyAuthorityDirective, JhiLoginModalComponent, KeviliSharedCommonModule, KeviliSharedLibsModule } from './';
import { ConfirmDialogComponent } from 'app/shared/common/confirm-dialog.component';
import { JhiTypeaheadScrollableComponent } from 'app/shared/select/typeahead-scrollable.component';
import { NotificationDialogComponent } from './common/notification-dialog/notification-dialog.component';
import { DocumentPreviewDialogComponent } from 'app/shared/document-preview/document-preview-dialog.component';
import { StockComponent } from 'app/shared/stock/stock.component';
import { BinMovementComponent } from './bin-movement/bin-movement.component';
import { NumberFormatPipe } from 'app/shared/util/number-format.pipe';

@NgModule({
  imports: [KeviliSharedLibsModule, KeviliSharedCommonModule],
  declarations: [
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    JhiTypeaheadScrollableComponent,
    NotificationDialogComponent,
    StockComponent,
    DocumentPreviewDialogComponent,
    BinMovementComponent,
    NumberFormatPipe
  ],
  entryComponents: [
    JhiLoginModalComponent,
    ConfirmDialogComponent,
    JhiTypeaheadScrollableComponent,
    NotificationDialogComponent,
    DocumentPreviewDialogComponent
  ],
  exports: [
    KeviliSharedCommonModule,
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    JhiTypeaheadScrollableComponent,
    DocumentPreviewDialogComponent,
    StockComponent,
    BinMovementComponent,
    NumberFormatPipe
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class KeviliSharedModule {
  static forRoot() {
    return {
      ngModule: KeviliSharedModule
    };
  }
}
