import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from 'app/core/login/login.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationDialogComponent } from 'app/shared/common/notification-dialog/notification-dialog.component';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService, private router: Router, private modalService: NgbModal) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.loginService.logout();
              if (this.router.url !== '/login' && !this.router.url.startsWith('/reset')) {
                const modalRef = this.modalService.open(NotificationDialogComponent, {
                  size: 'lg',
                  backdrop: 'static'
                });
                modalRef.componentInstance.message = 'Teie sessioon on aegunud. Palun logige uuesti sisse.';
                this.router.navigate(['/login']);
              }
            }
          }
        }
      )
    );
  }
}
