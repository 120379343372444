import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { DocumentsService } from 'app/selfservice/documents/documents.service';
import { Contract } from 'app/core/contract/contract.model';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'jhi-document-preview-dialog',
  templateUrl: './document-preview-dialog.component.html',
  styleUrls: ['../../selfservice/selfservice.scss']
})
export class DocumentPreviewDialogComponent implements OnInit {
  filename: string = null;
  fileContent: any = null;
  contentUrl: any = null;
  contract: Contract = null;
  pdfUrl: string = null;
  printJS = require('print-js');

  @ViewChild('pdfobject') pdfobject: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    public http: HttpClient,
    public documentsService: DocumentsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.pdfUrl !== null) {
      this.sharedService.getPDF(this.pdfUrl).subscribe(res => this.setContent(res));
    } else if (this.fileContent !== null) {
      this.setContent(this.fileContent);
    } else {
      this.documentsService.getFile(this.filename).subscribe(res => {
        this.setContent(res.body);
      });
    }
  }

  setContent(content) {
    this.fileContent = content;
    this.contentUrl = URL.createObjectURL(this.fileContent);
    if (this.filename.endsWith('.pdf')) {
      this.pdfobject.nativeElement.setAttribute('data', this.contentUrl);
    }
  }

  print() {
    this.printJS(this.contentUrl);
    this.clear();
  }

  download() {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('type', 'hidden');

    if (this.contract !== null && this.contract.contractBDOC !== '') {
      link.download = this.filename.replace('.pdf', '.bdoc');
      link.href = URL.createObjectURL(this.documentsService.getContractBDOC(this.contract.contractBDOC));
    } else {
      link.href = this.contentUrl;
      link.download = this.filename;
    }

    link.click();
    this.clear();
  }

  clear() {
    this.activeModal.dismiss('cancel');
  }
}
