/**
 * Angular bootstrap Date adapter
 */
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<Moment> {
  fromModel(date: Moment): NgbDateStruct {
    if (date != null && moment.isMoment(date) && date.isValid()) {
      return { year: date.year(), month: date.month() + 1, day: date.date() };
    }
    return null;
  }

  toModel(date: NgbDateStruct): Moment {
    return date ? moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD') : null;
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('.');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ? `${isNumber(date.day) ? padNumber(date.day) : ''}.${isNumber(date.month) ? padNumber(date.month) : ''}.${date.year}` : '';
  }
}

const I18N_VALUES = {
  et: {
    weekdays: ['E', 'T', 'K', 'N', 'R', 'L', 'P'],
    monthsSh: ['Jaan', 'Veeb', 'Märts', 'Apr', 'Mai', 'Juuni', 'Juuli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dets'],
    months: ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember']
  }
};

@Injectable()
export class I18n {
  language = 'et';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].monthsSh[month - 1];
  }

  getMonthFullName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

export function getDateRange(value: string) {
  let tfrom = moment();
  let tto;
  if (value === 'yesterday') {
    tfrom = tfrom.subtract(1, 'days');
  } else if (value === 'isoWeek' || value === 'month' || value === 'year') {
    tfrom = tfrom.startOf(value);
    tto = tfrom.clone().endOf(value);
  } else if (value === 'lastWeek') {
    tfrom = tfrom.startOf('isoWeek').subtract(1, 'week');
    tto = tfrom.clone().endOf('isoWeek');
  } else if (value === 'lastMonth') {
    tfrom = tfrom.startOf('month').subtract(1, 'month');
    tto = tfrom.clone().endOf('month');
  } else if (value === 'lastYear') {
    tfrom = tfrom.startOf('year').subtract(1, 'year');
    tto = tfrom.clone().endOf('year');
  } else if (value.startsWith('season-')) {
    const seasonYear = Number(value.substr(7));
    tto = stringToMoment(seasonYear + '-07-14');
    tfrom = stringToMoment(seasonYear - 1 + '-07-15');
  } else if (value.startsWith('year-')) {
    const seasonYear = value.substr(5);
    tfrom = moment([seasonYear]);
    tto = moment([seasonYear]).endOf('year');
  }
  return { from: tfrom, to: tto ? tto : tfrom.clone() };
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function momentDateToString(date) {
  if (date) {
    if (isString(date)) {
      return date;
    }
    if (moment.isMoment(date) && date.isValid()) {
      return date.format('YYYY-MM-DD');
    }
  }
  return null;
}

export function stringToMoment(date) {
  if (date) {
    if (isString(date)) {
      return moment(date, 'YYYY-MM-DD');
    }
  }
  return null;
}

export function getFiveSeasons() {
  const current = moment();
  let upToYear: number;
  if (current.month() >= 7 || (current.month() === 7 && current.date() >= 15)) {
    upToYear = current.add(1, 'years').year();
  } else {
    upToYear = current.year();
  }
  upToYear++;
  const seasons = [];
  for (let i = 0; i < 5; i++) {
    seasons.push({
      display: (upToYear - i - 1).toString().substr(2) + '/' + (upToYear - i).toString().substr(2),
      year: upToYear - i
    });
  }
  return seasons;
}

export function getFiveYears() {
  const upToYear = moment()
    .add(1, 'years')
    .year();
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push({
      year: upToYear - i
    });
  }
  return years;
}
