import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_MEMBER,
  ROLES_MEMBER_AGENT,
  ROLES_MEMBER_AGENT_NON_MEMBER,
  ROLES_TERMINAL_OPERATOR_TECHNICIAN,
  VERSION
} from 'app/app.constants';
import { AccountService, LoginService } from 'app/core';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { JhiEventManager } from 'ng-jhipster';
import { SharedService } from 'app/shared/shared.service';
import { AgentService } from 'app/selfservice/agent.service';
import { Contact } from 'app/core/user/contact.model';
import { Customer } from 'app/core/customer/customer.model';
import { CustomerService } from 'app/selfservice/customer/customer.service';

@Component({
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnInit {
  inProduction: boolean;
  isNavbarCollapsed: boolean;
  swaggerEnabled: boolean;
  version: string;
  userName: string;
  isTerminal: boolean;
  contacts: Contact[];
  selectedContact: Contact;
  customer: Customer;

  ROLES_TERMINAL_OPERATOR_TECHNICIAN = ROLES_TERMINAL_OPERATOR_TECHNICIAN;
  ROLES_MEMBER_AGENT_NON_MEMBER = ROLES_MEMBER_AGENT_NON_MEMBER;
  ROLES_MEMBER_AGENT = ROLES_MEMBER_AGENT;
  ROLE_ADMIN = ROLE_ADMIN;

  constructor(
    private loginService: LoginService,
    private eventManager: JhiEventManager,
    private accountService: AccountService,
    private profileService: ProfileService,
    public sharedService: SharedService,
    private agentService: AgentService,
    private customerService: CustomerService,
    private router: Router
  ) {
    this.version = VERSION ? 'v' + VERSION : '';
    this.isNavbarCollapsed = true;
  }

  ngOnInit() {
    this.profileService.getProfileInfo().then(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.swaggerEnabled = profileInfo.swaggerEnabled;
    });
    this.eventManager.subscribe('authenticationSuccess', () => {
      this.fetchUserName();
      this.fetchContacts();
      this.fetchCustomer();
    });
    this.fetchUserName();
    this.fetchContacts();
    this.fetchCustomer();
  }

  private fetchUserName() {
    this.accountService.identity().then(account => {
      this.userName = account.contactName;
      this.isTerminal = false;
      account.authorities.forEach(role => {
        if (ROLES_TERMINAL_OPERATOR_TECHNICIAN.includes(role)) {
          this.isTerminal = true;
        }
      });
    });
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  logout() {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['login']);
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  fetchContacts() {
    this.accountService.identity().then(account => {
      this.accountService.getSelectedContact().subscribe(res => (this.selectedContact = res.body));
      if (account.authorities.includes(ROLE_AGENT)) {
        this.agentService.fetchRepresentedUsers().subscribe(res => {
          this.contacts = res.body;
          this.contacts = this.contacts.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
        });
      } else {
        this.contacts = account.contacts.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
      }
    });
  }

  fetchCustomer() {
    this.accountService.identity().then(account => {
      if (account.authorities.includes(ROLE_MEMBER) || account.authorities.includes(ROLE_AGENT)) {
        this.customerService.fetchCustomer().subscribe(res => {
          this.customer = res.body;
        });
      }
    });
  }

  changeSelectedContact(contact) {
    this.selectedContact = contact;
    this.accountService.selectContact(contact.navNo).subscribe(res => {
      this.collapseNavbar();
      this.refresh();
    });
  }

  refresh() {
    window.location.reload();
  }

  navigateHome() {
    this.collapseNavbar();
    if (this.isTerminal) {
      this.router.navigate(['terminal/imports'], { queryParamsHandling: 'preserve' });
    } else {
      this.router.navigate(['']);
    }
  }
}
