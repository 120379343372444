import { AfterViewInit, Component, ElementRef, OnInit, Renderer } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, LoginService, StateStorageService } from 'app/core';
import { JhiEventManager } from 'ng-jhipster';

@Component({
  selector: 'jhi-login-comp',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {
  authenticationError: boolean;
  authenticationErrorIdCard: boolean;
  authenticationErrorIdCardUserNotActive: boolean;
  authenticationErrorSmartIdMid = '';
  showSmartIdFields = false;
  showMidFields = false;
  verificationCode = '';

  smartIdForm = this.fb.group({
    idCode: ['', Validators.required]
  });

  midForm = this.fb.group({
    idCode: ['', Validators.required],
    phoneNr: ['', Validators.required]
  });

  loginForm = this.fb.group({
    username: [''],
    password: [''],
    rememberMe: [true]
  });

  constructor(
    private eventManager: JhiEventManager,
    private loginService: LoginService,
    private accountService: AccountService,
    private stateStorageService: StateStorageService,
    private elementRef: ElementRef,
    private renderer: Renderer,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.accountService.identity().then(() => {
      if (this.accountService.isAuthenticated()) {
        this.router.navigate(['']);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#username'), 'focus', []), 0);
  }

  login() {
    this.loginService
      .login({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('rememberMe').value
      })
      .then(() => this.onLoginSuccess())
      .catch(() => {
        this.removeErrorMessages();
        this.authenticationError = true;
      });
  }

  idCardAuth() {
    this.loginService
      .loginIdCard()
      .then(() => this.onLoginSuccess())
      .catch(resp => {
        this.removeErrorMessages();
        if (resp.status === 401) {
          this.authenticationErrorIdCardUserNotActive = true;
        } else {
          this.authenticationErrorIdCard = true;
        }
      });
  }

  smartIdAuth() {
    this.removeErrorMessages();
    this.loginService.loginSmartIdInit(this.smartIdForm.get('idCode').value).subscribe(
      response => {
        this.verificationCode = response.body.code;
        this.loginService
          .loginSmartIdPoll()
          .then(() => this.onLoginSuccess())
          .catch(resp => {
            this.onError(resp);
          });
      },
      () => (this.authenticationError = true)
    );
  }

  midAuth() {
    this.removeErrorMessages();
    const userRequest = { idCode: this.midForm.get('idCode').value, phoneNumber: '+372' + this.midForm.get('phoneNr').value };
    this.loginService.loginMobileIdInit(userRequest).subscribe(
      response => {
        this.verificationCode = response.body.code;
        this.loginService
          .loginMobileIdPoll()
          .then(() => this.onLoginSuccess())
          .catch(resp => {
            this.onError(resp);
          });
      },
      () => (this.authenticationError = true)
    );
  }

  requestResetPassword() {
    this.router.navigate(['/reset', 'request']);
  }

  private onLoginSuccess() {
    this.removeErrorMessages();
    this.router.navigate(['']);

    this.eventManager.broadcast({
      name: 'authenticationSuccess',
      content: 'Sending Authentication Success'
    });
    const redirect = this.stateStorageService.getUrl();
    if (redirect) {
      this.stateStorageService.storeUrl(null);
      this.router.navigateByUrl(redirect);
    }
  }
  showSmartIdFileds() {
    this.showMidFields = false;
    this.showSmartIdFields = true;
    this.verificationCode = '';
  }

  showMidFileds() {
    this.showSmartIdFields = false;
    this.showMidFields = true;
    this.verificationCode = '';
  }

  cancelLogin() {
    this.verificationCode = '';
    this.showSmartIdFields = false;
    this.showMidFields = false;
    this.smartIdForm.reset();
    this.midForm.reset();
  }

  removeErrorMessages() {
    this.authenticationError = false;
    this.authenticationErrorIdCard = false;
    this.authenticationErrorIdCardUserNotActive = false;
    this.authenticationErrorSmartIdMid = '';
  }

  onError(resp) {
    this.removeErrorMessages();
    if (resp.status === 401) {
      this.authenticationErrorIdCardUserNotActive = true;
    } else {
      this.authenticationErrorSmartIdMid = resp.error.errorMessage;
    }
    this.cancelLogin();
  }
}
