import { Contact } from 'app/core/user/contact.model';
import { UserLocation } from 'app/core/user/user-location.model';

export interface IUser {
  id?: any;
  login?: string;
  contactName?: string;
  activated?: boolean;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  idCode?: string;
  contacts?: Contact[];
  locations?: UserLocation[];
}

export class User implements IUser {
  constructor(
    public id?: any,
    public login?: string,
    public contactName?: string,
    public activated?: boolean,
    public authorities?: any[],
    public createdBy?: string,
    public createdDate?: Date,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Date,
    public password?: string,
    public idCode?: string,
    public contacts?: Contact[],
    public allContacts?: Contact[],
    public locations?: UserLocation[]
  ) {
    this.id = id ? id : null;
    this.login = login ? login : null;
    this.contactName = contactName ? contactName : null;
    this.activated = activated ? activated : false;
    this.authorities = authorities ? authorities : null;
    this.createdBy = createdBy ? createdBy : null;
    this.createdDate = createdDate ? createdDate : null;
    this.lastModifiedBy = lastModifiedBy ? lastModifiedBy : null;
    this.lastModifiedDate = lastModifiedDate ? lastModifiedDate : null;
    this.password = password ? password : null;
    this.idCode = idCode ? idCode : null;
    this.contacts = contacts ? contacts : null;
    this.allContacts = contacts ? contacts : null;
    this.locations = locations ? locations : [];
  }
}
