import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from 'app/core/customer/customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public resourceUrl = SERVER_API_URL + 'api/customer/';

  constructor(private http: HttpClient) {}

  fetchCustomer(): Observable<HttpResponse<Customer>> {
    return this.http.get<Customer>(this.resourceUrl + 'factoring', { observe: 'response' });
  }

  getBalance(): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrl + 'balance', { observe: 'response' });
  }
}
