import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jhi-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  private confirmCallback: () => any;
  private cancelCallback: () => any;
  title;
  message;
  noCancel = false;
  yesButton = 'Jah';

  constructor(public activeModal: NgbActiveModal, private parentModalRef: NgbActiveModal) {
    this.parentModalRef = null;
  }

  setParentModal(ref: NgbActiveModal) {
    this.parentModalRef = ref;
  }

  setTitle(title: string) {
    this.title = title;
  }

  setMessage(message: string) {
    this.message = message;
  }

  setNoCancel(noCancel: boolean) {
    this.noCancel = noCancel;
  }

  setYesButton(yesButton: string) {
    this.yesButton = yesButton;
  }

  onConfirm(callback: () => any): ConfirmDialogComponent {
    this.confirmCallback = callback;
    return this;
  }
  onCancel(callback: () => any): ConfirmDialogComponent {
    this.cancelCallback = callback;
    return this;
  }

  confirm(): any {
    this.activeModal.dismiss(true);
    if (this.parentModalRef) {
      this.parentModalRef.dismiss(true);
    }
    if (this.confirmCallback) {
      return this.confirmCallback();
    }
  }

  cancel(): any {
    this.activeModal.dismiss(true);
    if (this.cancelCallback) {
      return this.cancelCallback();
    }
  }
}
